import React, { memo, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Panel } from '@sdflc/ui';
import { MenuHeader, FoodMenu, DefaultLayout, PageLoading } from '../components';
import { withProviders } from '../hoc';
import { SquareUpContext } from '../contexts';
import queryString from 'query-string';
import { isBrowser, setUsePos } from '../utils';
import { colors } from '../config';

const MenuPage = memo((props) => {
  const { menu, loadingData, firstTimeLoading, error } = useContext(SquareUpContext);

  if (firstTimeLoading) {
    return <PageLoading />;
  }

  const params = isBrowser() ? queryString.parse(window.location.search) : {};

  if (params.usePos != undefined) {
    setUsePos(!!params.usePos);
  }

  return (
    <Fragment>
      <DefaultLayout title='Menu'>
        <MenuHeader hideTime />
        <Panel padding={'20px'}>
          {error ? <span>{JSON.stringify(error.message)}</span> : null}
          {!error ? (
            <FoodMenu
              menu={menu['Menu']}
              isLoading={loadingData}
              firstTimeLoading={firstTimeLoading}
              vertical
              columnsQty={3}
            />
          ) : null}
        </Panel>
        {}
      </DefaultLayout>
      {Object.keys(params).length > 0 && (
        <Panel padding={'20px'} color={colors.gray}>
          {JSON.stringify(params, null, 2)}
        </Panel>
      )}
    </Fragment>
  );
});

MenuPage.displayName = 'MenuPage';

MenuPage.propTypes = {};

MenuPage.defaultProps = {};

export default withProviders(MenuPage);
